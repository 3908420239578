@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
#leftPlan{
   width: 400px;
   height: 100%;
   min-height: calc(100vh - 70px);
   // position: absolute;
   // right: 0;
   // top: 0;
   background-color: #ffffff;
   box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
}
