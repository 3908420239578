@charset "UTF-8";
/**
 * @description 全局主题变量配置
 */
:export {
  menu-color: #222222 !important;
  menu-color-active: #e62129 !important;
  menu-background: #1890ff;
  column-second-menu-background: #fff;
  menu-main-color-active: #1890ff;
}