@font-face {  
    font-family:"庞门正道标题体";
     /*注意 每次添加
    format('embedded-opentype')都会出错 我没加这个为了兼容IE9的format 可能是编译的问题 */
    src: url("../font/PangMenZhengDao.ttf") format('truetype') ;
    font-weight: normal;  
    font-style: normal;
}
@font-face {  
    font-family:"机械数字2";
     /*注意 每次添加
    format('embedded-opentype')都会出错 我没加这个为了兼容IE9的format 可能是编译的问题 */
    src: url("../font/Technology.ttf") format('truetype') ;
    font-weight: normal;  
    font-style: normal;
}
@font-face {  
    font-family:"机械数字";
     /*注意 每次添加
    format('embedded-opentype')都会出错 我没加这个为了兼容IE9的format 可能是编译的问题 */
    src: url("../font/SevenSegment.ttf") format('truetype') ;
    font-weight: normal;  
    font-style: normal;
}
@font-face {  
    font-family:"Flipper";
     /*注意 每次添加
    format('embedded-opentype')都会出错 我没加这个为了兼容IE9的format 可能是编译的问题 */
    src: url("../font/flipper2017.ttf") format('truetype') ;
    font-weight: normal;  
    font-style: normal;
}