@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
.vab-header {
  display: flex;
  align-items: center;
  justify-items: flex-end;
  height: $base-top-bar-height;
  // background: $base-menu-background;
  color: #fff !important;
  background: url('~@/assets/sys/nav_bg.png') no-repeat;
  background-size: 100% 100% !important;

  .vab-main {
    padding: 0 $base-padding 0 $base-padding;

    .right-panel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: $base-top-bar-height;

      :deep() {
        > .el-menu--horizontal.el-menu
          > .el-submenu
          > .el-submenu__title
          > .el-submenu__icon-arrow {
          float: right;
          margin-top: #{math.div($base-top-bar-height - 11, 2)} !important;
        }

        > .el-menu--horizontal.el-menu > .el-menu-item {
          .el-tag {
            margin-top: #{math.div($base-top-bar-height, 2)- 7.5} !important;
            margin-left: 5px;
          }

          .vab-dot {
            float: right;
            margin-top: #{math.div($base-top-bar-height - 6, 2) + 1};
          }

          @media only screen and (max-width: 1199px) {
            .el-tag {
              display: none;
            }
          }
        }

        .el-menu {
          &.el-menu--horizontal {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: $base-top-bar-height;
            border-bottom: 0 solid transparent !important;

            .el-menu-item,
            .el-submenu__title {
              height: #{math.div($base-top-bar-height, 1.3)};
              padding: 0 $base-padding;
              line-height: #{math.div($base-top-bar-height, 1.3)};
            }

            > .el-menu-item,
            > .el-submenu {
              height: $base-top-bar-height;
              line-height: $base-top-bar-height;

              .el-submenu__icon-arrow {
                float: right;
                margin-top: #{math.div($base-menu-item-height - 16, 2)};
              }

              > .el-submenu__title {
                display: flex;
                align-items: flex-start;
                height: $base-top-bar-height;
                line-height: $base-top-bar-height;
              }
            }
          }

          [class*='ri-'] {
            margin-left: 0;
            color: rgba($base-color-white, 0.9);
            cursor: pointer;
          }

          .el-submenu,
          .el-menu-item {
            i {
              color: inherit;
            }

            &.is-active {
              border-bottom: 0 solid transparent;

              .el-submenu__title {
                border-bottom: 0 solid transparent;
              }
            }
          }

          .el-menu-item {
            &.is-active {
              background: $base-color-blue !important;
            }
          }
        }

        .user-name {
          color: rgba($base-color-white, 0.9);
        }

        .user-name + i {
          color: rgba($base-color-white, 0.9);
        }

        [class*='ri-'] {
          margin-left: $base-margin;
          color: rgba($base-color-white, 0.9);
          cursor: pointer;
        }

        button {
          svg {
            margin-right: 0;
            color: rgba($base-color-white, 0.9);
            cursor: pointer;
            fill: rgba($base-color-white, 0.9);
          }
        }
      }
    }
  }
}
