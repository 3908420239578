@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
.vab-layout-common {
  .vab-main {
    padding-top: 70px;
    .fixed-header {
      left: $base-left-menu-width;
      width: $base-right-content-width;
    }
  }

  :deep() {
    .vab-tabs-content {
      width: calc(
        100% - 60px - #{$base-font-size-default} - #{$base-padding} - 2px
      ) !important;
    }

    .vab-header {
      .vab-main {
        width: 100%;
        margin: auto $base-margin;
      }
    }
  }
}
