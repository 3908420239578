@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
.video-management-wrap {
  .chooseGoods {
    .btn {
      margin-left: 35px;
    }
    .el-dialog__header {
      padding: 10px 10px;
      line-height: 40px;
    }
    .el-dialog__body {
      padding: 0;
      background: #f8f8f8;
      overflow: hidden;
    }
    .el-dialog__footer {
      text-align: center;
    }
    .video-style {
      width: 250px;
      height: 440px;
      cursor: pointer;
      padding: 10px;
      margin: 15px;
      border: 1px solid #ccc;
      border-radius: 3px;
      position: relative;
      .left-video {
        .mark {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          line-height: 144px;
          color: #fff;
          text-align: center;
          font-size: 60px;
          width: 144px;
          z-index: 1;
          background: rgba(0, 0, 0, 0.4);
        }
        .del-icon {
          position: absolute;
          top: 5px;
          right: 5px;
        }
        .del-icon:hover {
          color: red;
        }
        .name {
          display: inline-block;
          width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: top;
        }
      }
    }
    .footer {
      text-align: center;
    }
  }
}
