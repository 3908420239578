@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";/**
 * @description 菜单背景
 */

 .vab-background > #app {
    $base-menu-background: url('~@/assets/sys/menu_bg.png') no-repeat;
  
    @mixin container {
      color: $base-color-white !important;
      background: $base-menu-background !important;
      background-size: 100% auto !important;
      background-position: center bottom !important;
      background-color: #EBEDF4 !important;
    }
    @mixin transparent {
    //   color: rgba(#222222, 1) !important;
      background: transparent !important;
    }
    @mixin active {
      &:hover {
        color: rgba(#E62129, 1) !important;
        background-color:  rgba(#E62129, 0.1) !important;
      }
  
      &.is-active {
        color: rgba(#E62129, 1) !important;
        background-color:  rgba(#E62129, 0.1) !important;
      }
    }
  
    .vab-side-bar:not(.is-collapse), 
    .comprehensive-bar-container {
      @include container;
  
      .el-menu {
        @include transparent;
  
        .el-menu-item,
        .el-submenu__title {
          @include transparent;
          @include active;
          i,
          svg {
            @include transparent;
          }
        }
      }
      .logo-container-vertical {
        @include transparent;
  
        .logo .vab-icon,
        .title {
          @include transparent;
        }
      }
    }

    .vab-side-bar.is-collapse {
      @include container;

      .el-menu {
        background-color: #EBEDF4 !important;
  
        .el-menu-item,
        .el-submenu__title {
          @include transparent;
          @include active;
          i,
          svg {
            @include transparent;
          }
        }
      }


    }
  
    
  }
  