@use "sass:math";@import "~@/bd/styles/variables/variables.module.scss";
.bd-layout-template-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  [class*='bd-layout-'] {
    position: relative;

    :deep() {
      .bd-layout-header {
        box-shadow: $base-box-shadow;
      }
    }

    &.fixed {
      padding-top: $base-nav-height + $base-tabs-height;
    }

    &.fixed.no-tabs-bar {
      padding-top: $base-nav-height;
    }
  }

  :deep() {
      .fixed-header {
        // 使用 fixed 定位，会导致导航菜单闪烁，具体看笔记记录；
        // position: absolute;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: $base-z-index + 1;
        width: 100%;
      }

      .vab-main {
        position: relative;
        width: auto;
        min-height: 100%;
        margin-left: $base-left-menu-width;
      }

      &.is-collapse-main {
        margin-left: $base-left-menu-width-min;

        .fixed-header {
          left: $base-left-menu-width-min;
          width: calc(100% - #{$base-left-menu-width-min});
        }
      }

      // .app-main-container {
      //     >section {
      //       >[class*='-container'] {
      //         min-height: calc(100vh - #{$base-nav-height} - 40px) !important;
      //       }
      //     }
      //   }
    }
}
